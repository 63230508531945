<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">设备状态</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2"  class="s-btn-exp a-mlr-17" @click="exportFile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-company-under-select label="商户" v-model="pageParam.params.companyId"></le-company-under-select>
                <le-input label="项目名称" v-model="pageParam.params.incomeName" />
                <le-input label="站点名称" v-model="pageParam.params.stationName" />
                <le-input label="设备code" v-model="pageParam.params.deviceCode" />
                <le-date-range 
                    ref="dateRange" 
                    label="日期" 
                    :clearable="false"
                    :pickerOptions="pickerOptions"  
                    :minDate.sync="startTime" 
                    :maxDate.sync="endTime" />
                <le-select-local-search label="设备种类" v-model="pageParam.params.deviceCategroy" :options="optionsDeviceCategroy" />
                <jd-input-condition label="日均收益" :value.sync="income" :condition.sync="pageParam.params.incomeOperate" inputType="money"></jd-input-condition>
                <jd-input-condition label="在线天数" :value.sync="pageParam.params.onlineDay" :condition.sync="pageParam.params.onlineOperate" inputType="integer"></jd-input-condition>
                <le-select-local-search label="是否绑定站点" v-model="pageParam.params.bindStation" :options="bindStationOptions" />
            </le-search-form>
            <le-pagview ref="deviceStatus" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.deviceStatusList">
                <el-table 
                    ref="deviceStatusList"
                    :data="tableData" 
                    :highlight-current-row="true" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }"
                    style="width: 100%">
                    <el-table-column type="expand" fixed="left">
                        <template slot-scope="props">
                            <el-form label-position="left" >
                                <el-form-item label="商户：" class="deviceStatusMore">
                                    <span>{{ props.row.companyName?props.row.companyName:'-' }}</span>
                                </el-form-item>
                                <el-form-item label="项目：" class="deviceStatusMore">
                                    <span>{{ props.row.incomeName?props.row.incomeName:'-' }}</span>
                                </el-form-item>
                                <el-form-item label="站点：" class="deviceStatusMore">
                                    <span>{{ props.row.stationName?props.row.stationName:'-' }}</span>
                                </el-form-item>
                                <el-form-item label="安装日期：">
                                    <span>{{ props.row.installTimeText?props.row.installTimeText:'-' }}</span>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceCode" fixed="left" label="设备code" min-width="120">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="toDeviceList(row)">{{ row.deviceCode?row.deviceCode:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceCategroyText" label="设备种类" fixed="left" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.deviceCategroyText?row.deviceCategroyText:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        v-for="(item,index) in tableThead"
                        :key="index"
                        prop="companyName" 
                        :label="item" 
                        min-width="130" >
                        <template slot-scope="{ row }">
                            <span 
                                :class="setDurationClass(row.list,item)" 
                                class="a-flex-1 a-flex-rcc">{{ row.list | getDurationVal(item) }}&nbsp;&nbsp;{{ row | getOrderNumVal(item) }}</span>
                            <!-- <span class="status-gray">离线</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="日均订单数"
                        fixed="right" 
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span v-if="row.deviceType == 60">-</span>
                            <span v-else>{{ row.dayAvgOrderNum || row.dayAvgOrderNum===0?row.dayAvgOrderNum:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="日均收益" 
                        fixed="right"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span v-if="row.deviceType == 60">-</span>
                            <span v-else>{{ row.dayAvgOrderIncome || row.dayAvgOrderIncome===0?'￥'+(row.dayAvgOrderIncome/100).toFixed(2):'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="name" 
                        label="累计在线天数" 
                        fixed="right"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.totalDay || row.totalDay===0?row.totalDay:'-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import util from '../../utils/util'
let _this
export default {
    data() {
        return {
            utils: util,
            tableData: [],
            tableThead: [], // 表头
            pageParam: {
                url: this.$Config.apiUrl.getDeviceOnlinePage,
                method: "post",
                params: {
                    companyId: '',
                    incomeName: '',
                    startTime: '',
                    endTime: '',
                    stationName: '',
                    deviceCode: '',
                    incomeOperate: 1, //日收益 1是>= ，2是<
                    onlineOperate: 1, //在线天数 1是>= ，2是<
                    income: '',
                    onlineDay: '',
                    deviceCategroy: '',
                    bindStation: '',
                },
                freshCtrl: 1,
            },
            income: '',//
            startTime: '',
            endTime: '',
            optionsDeviceCategroy: [],
            bindStationOptions: [{
                label: '全部',
                value: ''
            },{
                label: '绑定',
                value: 1
            },{
                label: '未绑定',
                value: 0
            }],
            onlineColor: '',//控制某个时长显示对应颜色
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e7; //只能选择今天及今天之后的日期
                    //return time.getTime() < Date.now() - 8.64e6; //只能选择今天之后的日期，今天的日期也不能选
                }
            },
        };
    },
    watch:{
        income (val) {
            this.pageParam.params.income = val?Number((val*100).toFixed(0)):''
        },
        searchDate: {
            deep: true,
            handler (newVal, oldVal) {
                let daysNum = this.$getDay.enumerateDaysBetweenDates(this.startTime,this.endTime).length
                if(daysNum > 31){
                    this.$message.error('日期跨度不得大于31天！')
                    this.startTime = this.pageParam.params.startTime
                    this.endTime = this.pageParam.params.endTime
                    this.$refs['dateRange'].value1 = [this.pageParam.params.startTime,this.pageParam.params.endTime]
                    return false
                }
                this.pageParam.params.startTime = this.startTime
                this.pageParam.params.endTime = this.endTime
            }
            
        }
    },
    computed:{
        searchDate () {
            return this.startTime + this.endTime
        }
    },
    filters:{
        initDic (value,dic) {
            if(value === '' || value === undefined || value === null){
                return ''
            }else{
                for(var i=0; i<dic.length;i++){
                    if(value==dic[i].value){
                        return dic[i].text
                    }
                }
            }
        },
        getDurationVal (arr, text) {
            let duration = arr.find(res=>{
                return res.countDate == text
            })
            if(duration){
                if(duration.onlineTime && duration.onlineTime>=60){
                    return _this.utils.secondToHoursMinute(duration.onlineTime)
                }else{
                    return '离线'
                }
            }else{
                return '离线'
            }
        },
        getOrderNumVal (row, text) {
            let arr = row.list
            let orderNum = arr.find(res=>{
                return res.countDate == text
            })
            if(row.deviceType == 60){ // 门禁没有订单数
                return ''
            }else if(orderNum){
                if(orderNum.onlineTime && orderNum.onlineTime>=60) { 
                    return (orderNum.orderNum?orderNum.orderNum:0) + '单'
                }else{
                    return ''
                }
                
            }else{
                return ''
            }
        }
    }, 
    created () {
        this.startTime = this.$getDay.getTodayBeforeDays(30)
        this.endTime = this.$getDay.getTodayBeforeDays(1)
        this.pageParam.params.startTime = this.startTime
        this.pageParam.params.endTime = this.endTime
        this.tableThead = this.$getDay.enumerateDaysBetweenDates(this.startTime,this.endTime)
        // 获取设备种类
        this.getDeviceCategroy()
        this.$getDic('onlineColor').then(res=>{ 
            this.onlineColor = res[0].text; 
            this.onlineColor=this.onlineColor*60*60 
        })
    },
    activated () {
		this.pageParam.freshCtrl++;
	},
    mounted () {
        _this = this
    },
    methods: {
        setTableData(data,fileUrl) {
            this.tableData = data;
        },
        handlerRest() {
            this.pageParam.params = {
                companyId: '',
                incomeName: '',
                startTime: this.$getDay.getTodayBeforeDays(30),
                endTime: this.$getDay.getTodayBeforeDays(1),
                stationName: '',
                deviceCode: '',
                incomeOperate: 1, //日收益 1是>= ，2是<
                onlineOperate: 1, //在线天数 1是>= ，2是<
                income: '',
                onlineDay: '',
                deviceCategroy: '',
                bindStation: '',
            };
            this.income = ''
            this.$refs['dateRange'].value1 = [this.$getDay.getTodayBeforeDays(30),this.$getDay.getTodayBeforeDays(1)]
            this.tableThead = this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime)
            this.handlerSearch()
        },
        handlerSearch() {
            let daysNum = this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime).length
            if(daysNum > 31){
                this.$message.error('日期跨度不得大于31天！')
                return false
            }
            this.tableThead = this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime)
            this.$nextTick(()=>{
                this.$refs.deviceStatusList.doLayout() // 解决动态设置表格表头是表格闪烁的问题
            })
            this.$refs['deviceStatus'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        getDeviceCategroy () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getDeviceCategroyList,
                method: "post",
                showLoading: false,
                params: {}
            }).then(res => {
                if (res.result.code == 0) {
                    this.optionsDeviceCategroy = res.result.data.map(item=>{
                        return {
                            label: item.deviceCategroy,
							value: item.id,
                        }
                    })
                    this.$nextTick(()=>{
                        this.optionsDeviceCategroy.unshift({
                            label: '全部',
							value: '',
                        })
                    })
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        exportFile () {
            this.$exfile({
                code: 1,
                fileName: this.pageParam.params.startTime + ' 至 ' + this.pageParam.params.endTime + ' 设备状态',
                startTime: this.pageParam.params.startTime,
                endTime: this.pageParam.params.endTime,
                params: this.pageParam.params
            })
        },
        setDurationClass (arr, text) {
            let duration = arr.find(res=>{
                return res.countDate == text
            })
            if(duration){
                if(duration.onlineTime > this.onlineColor){
                    return 'status-green'
                }else if(duration.onlineTime <= this.onlineColor && duration.onlineTime >= 60){
                    return 'status-yellow'
                }else{
                    return 'status-gray'
                }
            }
        },
        toDeviceList (datas) {
            window.sessionStorage.setItem('fromPath','device-status')
            this.$router.push({
                path:'/device/device-list',
                query: {
                    deviceCode: datas.deviceCode?datas.deviceCode:''
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
    width: 90px !important;
}
.deviceStatusMore{
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    span{
        width: 230px;
    }
}
.status-green{
    background: #67C23ACC;
    min-width: 100px;
    color: #ffffff;
    font-size: 12px;
    border-radius: 3px;
    padding: 4px;
    letter-spacing: 1px;
}
.status-yellow{
    background: #E6A23C;
    min-width: 100px;
    color: #ffffff;
    font-size: 12px;
    border-radius: 3px;
    padding: 4px;
    letter-spacing: 1px;
}
.status-gray{
    background: #909399;
    min-width: 100px;
    color: #ffffff;
    font-size: 12px;
    border-radius: 3px;
    padding: 4px;
    text-align: center;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>
